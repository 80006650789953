.video-container1 {
  background-color: rgb(238, 238, 238);
  position: relative;
  margin-top: 2%;
  /* margin-bottom: 2%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 15px;
  overflow: hidden;
}

.video-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
  width: 40vw; /* 40% of the actual width of the screen */
  height: 22.5vw; /* Maintain 16:9 aspect ratio */
}

.video-thumbnail iframe {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.video-info {
  text-align: center;
  color: black;
  margin: 35px 10px;
}

.video-info p {
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
}

.video-info h2 {
  font-size: 1.5rem;
  margin: 0;
  text-transform: lowercase;
}

.background-view-text1 {
  /* background-color: aqua; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  position: relative;
  margin-top: 2px;
  /* margin-bottom: 50px; */
}

.background-text1 {
  font-size: 150px;
  /* margin-bottom: -100px; */
  color: rgba(0, 0, 0, 0.1);
  /* z-index: -1; */
  /* text-align: left; */
  /* flex: 1; */
}

@media (max-width: 2000px) {
  .video-thumbnail {
    width: 50vw;
    height: 28.125vw; 
  }
  .background-text1 {
    font-size: 130px;
  }

}

@media (max-width: 1768px) {
  .video-thumbnail {
    width: 55vw;
    height: 30.9375vw; 
  }
  .background-text1 {
    font-size: 120px;
  }

}

@media (max-width: 1368px) {
  .video-thumbnail {
    width: 68vw;
    height: 38.25vw;
  }

  .background-text1 {
    font-size: 100px;
  }

}

@media (max-width: 1000px) {
  .video-thumbnail {
    width: 90vw;
    height: 50.625vw; 
  }

  .video-info p {
    font-size: 0.875rem;
  }

  .video-info h2 {
    font-size: 1.25rem;
  }

  .background-text1 {
    font-size: 90px;
  }

}

@media (max-width: 768px) {
  .background-text1 {
    font-size: 80px;
  }

  .video-thumbnail {
    width: 80vw; /* 40% of the actual width of the screen */
    height: 43vw; /* Maintain 16:9 aspect ratio */
  }

  .video-info p {
    font-size: 0.75rem;
  }

  .video-info h2 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {

  .video-thumbnail {
    width: 90vw;
    height: 130vw; /* 16:9 aspect ratio */
    /* Adjust the aspect ratio (width / height) as needed */
  }
  .background-text1 {
    font-size: 50px;
  }

  .background-view-text1 {
    /* flex-direction: row;  */
    justify-content: space-between; 
    margin-bottom: 10px;
  }
  
  .view-all1 {
    /* margin-top: 40px; */
    margin-right: -30px;
  }

  .video-info p {
    font-size: 0.625rem;
  }

  .video-info h2 {
    font-size: 0.875rem;
  }
}

@media (max-width: 300px) {
  .video-info p {
    font-size: 0.5rem;
  }

  .video-info h2 {
    font-size: 0.75rem;
  }

  .view-all1 {
    font-size: 12px;
    padding: 5px 10px;
  }

  .background-text1 {
    font-size: 60px;
  }
}

.nav-arrow-video-section {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #070707;
  padding: 0 10px;
  outline: none;
}

.nav-arrow-video-section:hover {
  color: #ccc;
}

.video-radio-buttons-xx {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.radio-label-xx {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0 5px;
}

.radio-input-xx {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.radio-button1-xx {
  display: inline-block;
  width: 16px; /* Smaller size */
  height: 16px;
  border: 2px solid #333;
  border-radius: 50%;
  margin-right: 5px;
  background: linear-gradient(145deg, #a0a0a0, #707070);
  box-shadow: 2px 2px 4px #5e5e5e, -2px -2px 4px #8e8e8e;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
  position: relative;
}

.radio-button1-xx::before {
  content: '';
  display: block;
  width: 12px; /* Inner circle for iris */
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centering the circle */
}

.radio-button1-xx::after {
  content: '';
  display: block;
  width: 4px; /* Pupil size */
  height: 4px;
  background-color: #333;
  border-radius: 50%;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5); /* Centering and scaling the pupil */
}

.radio-input-xx:checked + .radio-button1-xx::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1); /* Centering the pupil when checked */
  animation: stare 1.5s infinite ease-in-out, blink 4s infinite ease-in-out;
}

.radio-input-xx:checked + .radio-button1-xx::before {
  animation: stare 1.5s infinite ease-in-out, blink 4s infinite ease-in-out;
}

.radio-input-xx:checked + .radio-button1-xx {
  border-color: #333;
  background: linear-gradient(145deg, #909090, #606060);
  box-shadow: inset 2px 2px 4px #5e5e5e, inset -2px -2px 4px #8e8e8e;
}

/* Upper eyelid */
.radio-button1-xx::after {
  content: '';
  display: block;
  width: 100%;
  height: 50%;
  background-color: #333;
  border-radius: 50% 50% 0 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s;
  transform-origin: top;
  transform: scaleY(0); /* Hidden by default */
}

.radio-input-xx:checked + .radio-button1-xx::after {
  transform: scaleY(1); /* Visible when checked */
}

@keyframes stare {
  0%, 100% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-40%, -50%);
  }
  50% {
    transform: translate(-50%, -40%);
  }
  75% {
    transform: translate(-60%, -50%);
  }
}

@keyframes blink {
  0%, 90%, 100% {
    transform: scaleY(1);
  }
  95% {
    transform: scaleY(0.1);
  }
}


/* --------------- Video Section Text Responsivess -------------- */


.video-info p {
  font-size: 1.5rem; /* Default font size */
  text-transform: uppercase;
  margin: 0;
}

.video-info h2 {
  font-size: 2.5rem; /* Default font size */
  margin: 0;
  text-transform: lowercase;
}

@media (max-width: 1000px) {
  .video-info p {
    font-size: 1.25rem; /* Adjusted font size for smaller screens */
  }

  .video-info h2 {
    font-size: 2rem; /* Adjusted font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .video-info p {
    font-size: 1rem; /* Further adjusted font size for smaller screens */
  }

  .video-info h2 {
    font-size: 1.5rem; /* Further adjusted font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .video-info p {
    font-size: 0.875rem; /* Font size for smallest screens */
  }

  .video-info h2 {
    font-size: 1.25rem; /* Font size for smallest screens */
  }
}
