
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; 
}


*, *::before, *::after {
  box-sizing: inherit;
}


.carousel-jar {
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 100%;
  /* background-color: aqua; */
  display: flex;
}

.arrow-section-music{
  display:flex;
  flex-direction: column;
}

.left-arrow-music, .right-arrow-music {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* background-color: bisque; */
  height: 100%;
  
}

.arrow-section-extra-space{
  display: none;
  height: 20%;
  /* background-color: brown; */
}

.music-and-details-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.music-container{
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  /* background-color: blueviolet; */
  overflow: hidden;
}

.music-details-container{
  padding: 10px;
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  /* background-color: blanchedalmond; */
}


.music-cover {
  max-width: 60%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.album-details {
  text-align: center;
}

.album-title{
  /* position: absolute; */
  top: 10px;
  /* background-color: #0056b3; */
  white-space: nowrap;
  font-weight: 600;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  font-size: 40px;
}

@media (min-width: 1700px) {
  .carousel-jar {
    height:600px;
  }

  .music-cover {
    width: 600px;
  }
}
@media (max-width: 1700px) {
  .carousel-jar {
    height:550px;
  }

  .music-cover {
    max-width: 55%;
  }
}

@media (max-width: 1500px) {
  .carousel-jar {
    margin-top: 0vh;
    margin-bottom: 0vh;
  }
 
  .music-cover {
    width: 60%;
    max-width: 100%;
  }
}
@media (max-width: 1350px) {
  .music-cover {
    width: 65%;
  }
}

@media (max-width: 1220px) {
  .music-cover {
    width: 70%;
  }
}


@media (max-width: 1100px) {
  .carousel-jar {
    height:490px;
  }
  .music-cover {
    width: 72%;
  }
}

@media (max-width: 950px) {
  .carousel-jar {
    height:450px;
  }
  .music-cover {
    width: 75%;
  }
}

@media (max-width: 850px) {

  .carousel-jar {
    height:550px;
    margin-top: 2vh;
    margin-bottom: 4vh;
  }
  .left-arrow-music, .right-arrow-music {
    height: 80%;
    
  }
  .music-and-details-container{
    flex-direction: column;
  }
  .music-container{
    justify-content: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
    
  }

  .music-cover {
    
    height: auto;
    width: 59%;
    
    
  }
  
  .music-details-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:20%;
    width: 100%;
  }
}

@media (max-width: 800px) {

  .carousel-jar {
    height: 530px;
  }
  .music-cover {
    width: 63%;
  }
}


@media (max-width: 750px) {

  .carousel-jar {
    height: 530px;
  }
  .music-cover {
    width: 66%;
  }
}

@media (max-width: 720px) {

  .carousel-jar {
    height: 530px;
  }
  .music-cover {
    width: 70%;
  }
}

@media (max-width: 650px) {

  .carousel-jar {
    height: 530px;
  }
  .music-cover {
    width: 80%;
  }
}

@media (max-width: 600px) {

  .carousel-jar {
    height: 480px;
  }
  .music-cover {
    width: 80%;
  }
}


@media (max-width: 550px) {

  .music-container{
    align-items: center;
    
  }

  .carousel-jar {
    height: 430px;
  }
  .music-cover {
    max-width: 95%;
  }

}

@media (max-width: 490px) {
  .carousel-jar {
    height: 370px;
  }
  
}

@media (max-width: 415px) {
  .carousel-jar {
    height: 330px;
  }
  
}

@media (max-width: 370px) {
  .carousel-jar {
    height: 300px;
  }
  
}

@media (max-width: 350px) {
  .carousel-jar {
    height: 270px;
  }
  .music-cover {
    min-width: 50px;
  }
}


/* -----------------   Left Arrow   ---------------------- */

.nav-button {
  /* background-color: #6c63ff; */
  margin: 1.3vw;
  color: #000000; /* White text */
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #4e47d0; /* Slightly darker purple for hover effect */
  transform: scale(1.1);
  color: salmon;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.nav-button:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.album-action {
  font-size: large;
  display: flex;
  gap: 0.5rem; /* Adds a gap between "STREAM" and "DOWNLOAD" */
  color: #105aaa;
  cursor: none;
}

.album-action span {
  cursor: none;
  text-decoration: none;
}

.album-action a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

.album-action a:hover {
  color: #0056b3; /* Darker shade of blue on hover */
}

.custom-cursor {
  position: absolute;
  width: 50px; /* Resize the cursor image */
  height: 50px; /* Resize the cursor image */
  pointer-events: none; /* Allow interactions with underlying elements */
  transform: translate(-50%, -50%); /* Center the cursor image on the pointer */
  z-index: 1000; /* Ensure the custom cursor is above other elements */
}

.view-all1 {
  /* right: 10px; */
  padding: 10px 20px;
  font-size: 14px;
  margin-right: -30px;
  align-self: flex-start;
  background-color: #7643b1;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  /* margin-top: 100px; */
  text-transform: uppercase;
}

.view-all1:hover {
  background-color: #333;
  cursor: pointer;
}

.background-view-text-music-section{
  padding-right: 20px;
  padding-left: 20px;
}


/* ---------------------- New ones ---------------------- */


/* .album-title {
  font-size: 40px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.album-action {
  text-align: center;
  display: flex;
  gap: 0.5rem;
  font-size: 28px;
  color: #105aaa;
  cursor: pointer;
} */

@media (max-width: 850px) {
  .album-title {
    font-size: 36px;
  }

  .album-action {
    font-size: 24px;
  }
}

@media (max-width: 550px) {
  .release-date {
    font-size: 20px;
  }

  .album-title {
    font-size: 32px;
  }

  .album-action {
    font-size: 20px;
  }
}

@media (max-width: 390px) {
  .release-date {
    font-size: 16px;
  }

  .album-title {
    font-size: 28px;
  }

  .album-action {
    font-size: 16px;
  }
}

