.footer {
    background-color: wheat;
    color: rgb(0, 0, 0);
    font-family: 'Arial';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh;
}

.footer-item {
    padding: 10px;
    font-size: small;
    /* letter-spacing: 0.05em;  */
}

.policies {
    display: flex;
    flex-wrap: wrap; /* Allows policies to wrap on smaller screens */
    justify-content: center; /* Centers the policies */
    padding: 10px 0;
}

.policy {
    padding: 0 1vw;
    margin: 5px 0;
    letter-spacing: 0.05em; /* Adjust letter spacing */
    color: rgb(0, 0, 0); /* Match footer text color */
    text-decoration: none;
    transition: color 0.3s;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    margin: 0 0.4em;
}

.policy:hover {
    color: #ccc; /* Change color on hover */
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 20px 0; */
}

.icon {
    color: white; /* Make icons white */
    font-size: 1.5rem; /* Adjust the size as needed */
    margin: 0 10px; /* Space between icons */
    transition: color 0.3s; /* Smooth transition for hover effect */
}

path {
    color: rgb(0, 0, 0);
}

.icon:hover {
    color: rgb(211, 68, 68); /* Keep icons white on hover */
}

.copyright {
    letter-spacing: 0.05em; /* Adjust letter spacing */
    font-size: small;
    text-align: center;
    padding: 10px;
}

@media (max-width: 768px) {
    .footer-item {
        font-size: 0.9rem; /* Adjusted font size for smaller screens */
    }

    .policy {
        font-size: 0.9rem; /* Adjusted font size for smaller screens */
    }

    .copyright {
        font-size: 0.8rem; /* Adjusted font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .footer-item {
        font-size: 0.8rem; /* Font size for smallest screens */
    }

    .policy {
        font-size: 0.8rem; /* Font size for smallest screens */
    }

    .copyright {
        font-size: 0.7rem; /* Font size for smallest screens */
    }
}
