.balance-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cover-photo {
    width: 100%;
    margin-bottom: 20px;
}

.cover-photo img {
    max-width: 100%;
    height: auto;
}

.lyrics {
    text-align: center;
    line-height: 1.6;
    font-size: 18px;
}

@media (max-width: 768px) {
    .lyrics {
        font-size: 16px;
    }
}
