/* Container for the detailed view of the video collection */
.video-detailed-view {
    padding: 20px;
    margin-left: 100px;
    margin-right: 100px;
}

/* Header styling for the video collection */
.video-detailed-view h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Grid layout for the video thumbnails */
.video-detailed-view .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

/* Styling for individual video thumbnails */
.video-detailed-view .video-grid img {
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for video thumbnails */
.video-detailed-view .video-grid img:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
    .video-detailed-view .video-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
}
